<template>
  <vca-card>
    <h2>{{ $t("taking.list.header") }}</h2>
    <vca-card class="shadowed vca-center">
      <TakingTable />
    </vca-card>
  </vca-card>
</template>

<script>
import TakingTable from "@/components/finance/takings/AsyncTakingTable";
export default {
  name: "TakingsList",
  components: { TakingTable },
  created() {
    this.$store.commit("takings/pg/route", "finances/taking");
    this.$store.commit("takings/pg/context", "takings/pg");
    this.$store.dispatch({
      type: "takings/pg/request",
      data: { initial: true },
    });
    //this.$store.commit("takings/list", null);
    if (Object.keys(this.$route.query).length == 0) {
      window.history.pushState(
        "",
        "",
        this.toQuery(this.$store.state.takings.query)
      );
    } else {
      this.$store.commit("takings/query", this.$route.query);
    }
    //this.$store.dispatch({ type: "takings/list" });
  },
  computed: {
    current: {
      get() {
        return this.$store.state.takings.current;
      },
      set(value) {
        this.$store.commit("takings/current", value);
      },
    },
  },
};
</script>
